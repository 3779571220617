$(document).on('turbolinks:load', function () {
  $(".alert" ).fadeOut(10000);

  $("input:radio[name=news_type]").click(function(evt) {
    $.ajax((this).closest('form').submit());
    //$(this).closest('form').submit();
  });

  $("body").on("click",".link_contact",function(){
    $(".message-modal").modal("show");
  });
    
});