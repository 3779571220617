$(document).on('turbolinks:load', function () {

	'use strict';

	var mobileMenuOutsideClick = function () {

		$(document).click(function (e) {
			var container = $("#mod-offcanvas, .js-mod-nav-toggle");
			if (!container.is(e.target) && container.has(e.target).length === 0) {

				if ($('body').hasClass('offcanvas')) {

					$('body').removeClass('offcanvas');
					$('.js-mod-nav-toggle').removeClass('active');

				}


			}
		});

	};


	var offcanvasMenu = function () {

		$('#page').prepend('<div id="mod-offcanvas" />');
		$('#page').prepend('<a href="#" class="js-mod-nav-toggle mod-nav-toggle mod-nav-white"><i></i></a>');
		var clone1 = $('.menu-1 > ul').clone();
		$('#mod-offcanvas').append(clone1);
		var clone2 = $('.menu-2 > ul').clone();
		$('#mod-offcanvas').append(clone2);



		$(window).resize(function () {

			if ($('body').hasClass('offcanvas')) {

				$('body').removeClass('offcanvas');
				$('.js-mod-nav-toggle').removeClass('active');

			}
		});
	};


	var burgerMenu = function () {

		$('body').on('click', '.js-mod-nav-toggle', function (event) {
			var $this = $(this);


			if ($('body').hasClass('overflow offcanvas')) {
				$('body').removeClass('overflow offcanvas');
			} else {
				$('body').addClass('overflow offcanvas');
			}
			$this.toggleClass('active');
			event.preventDefault();

		});
	};




	var goToTop = function () {

		$('.js-gotop').on('click', function (event) {

			event.preventDefault();

			$('html, body').animate({
				scrollTop: $('html').offset().top
			}, 500, 'easeInOutExpo');

			return false;
		});

		$(window).scroll(function () {

			var $win = $(window);
			if ($win.scrollTop() > 150) {
				$('.js-top').addClass('active');
			} else {
				$('.js-top').removeClass('active');
			}

		});

	};

	var playVid = (el) => {
		el.play();
	}

	var stopVid = (el) => {
		el.pause();
		el.currentTime = 0;
	}

	$('#modalHome').on('shown.bs.modal', function (e) {
		var vid = document.getElementById("videoid");
		playVid(vid);
	})

	var getCookie = (cname) => {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for(var i = 0; i <ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
						c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
						return c.substring(name.length, c.length);
				}
		}
		return "";
	}

	// Loading page
	var loaderPage = function () {
		$(".mod-loader").fadeOut("slow");
	};

	window.openShareWin = (url, locale) => {

		var windowWidth = 600;
		var windowHeight = 400;
		var centerWidth = (window.screen.width - windowWidth) / 2;
		var centerHeight = (window.screen.height - windowHeight) / 2;
		var newWindow = window.open(url, "ShareWindow", 'resizable=0,width=' + windowWidth + 
				',height=' + windowHeight + 
				',left=' + centerWidth + 
				',top=' + centerHeight);
	
		if(newWindow == undefined){
			popUpMsg = (locale == 'es')?'Deshabilite su bloqueador de ventanas emergentes':'Disable your pop-up blocker';
			alert(popUpMsg);
		}else{
			newWindow.focus();
			return newWindow;
		} 
		 
	}

	$(document).ready(function () {
		// $("#btncont").click(function (event) {
		// 	$("#toggleContacto").collapse();
		// });

		// if ($("#togglebtn").length){
		// 	$("#togglebtn").click(function(){
		// 		$("#toggleNoticias").collapse('toggle');
		// 	});
		// }

		if ($('#modalVideo').length) {
			$('#modalVideo').on('shown.bs.modal', function (e) {
				var vid = document.getElementById("videoid");
				playVid(vid);
			})

			$('#modalVideo').on('hide.bs.modal', function (e) {
				var vid = document.getElementById("videoid");
				stopVid(vid);
			})
		}

		if ($('#modalHome').length) {

			if(getCookie("videointro") != "true"){
				$("#modalHome").modal("show");
				document.cookie = "videointro=true";
			}

			$('#modalHome').on('hide.bs.modal', function (e) {
				var vid = document.getElementById("videoid");
				stopVid(vid);
			})
		}

		if ($('.mod_acerca').length) {
			$('.nav-tabs-dropdown').each(function (i, elm) {
				$(elm).text($(elm).next('ul').find('li.active a').text());
			});
			$('.nav-tabs-dropdown').on('click', function (e) {
				e.preventDefault();
				$(e.target).toggleClass('open').next('ul').slideToggle();
			});
			$('#nav-tabs-wrapper a[data-toggle="tab"]').on('click', function (e) {
				e.preventDefault();
				$(e.target).closest('ul').hide().prev('a').removeClass('open').text($(this).text());
			});
		}

		if ($('.evento_tabs').length) {
			var a = {
				accordionOn: ["xs"]
			};
			$.fn.responsiveTabs = function (e) {
				var t = $.extend({}, a, e),
					s = "";
				return $.each(t.accordionOn, function (a, e) {
					s += " accordion-" + e
				}), this.each(function () {
					var a = $(this),
						e = a.find("> li > a"),
						t = $(e.first().attr("href")).parent(".tab-content"),
						i = t.children(".tab-pane");
					a.add(t).wrapAll('<div class="responsive-tabs-container" />');
					var n = a.parent(".responsive-tabs-container");
					n.addClass(s), e.each(function (a) {
						var t = $(this),
							s = t.attr("href"),
							i = "",
							n = "",
							r = "";
						t.parent("li").hasClass("active") && (i = " active"), 0 === a && (n = " first"), a === e.length - 1 && (r = " last"), t.clone(!1).addClass("accordion-link" + i + n + r).insertBefore(s)
					});
					var r = t.children(".accordion-link");
					e.on("click", function (a) {
						a.preventDefault();
						var e = $(this),
							s = e.parent("li"),
							n = s.siblings("li"),
							c = e.attr("href"),
							l = t.children('a[href="' + c + '"]');
						s.hasClass("active") || (s.addClass("active"), n.removeClass("active"), i.removeClass("active"), $(c).addClass("active"), r.removeClass("active"), l.addClass("active"))
					}), r.on("click", function (t) {
						t.preventDefault();
						var s = $(this),
							n = s.attr("href"),
							c = a.find('li > a[href="' + n + '"]').parent("li");
						s.hasClass("active") || (r.removeClass("active"), s.addClass("active"), i.removeClass("active"), $(n).addClass("active"), e.parent("li").removeClass("active"), c.addClass("active"))
					})
				})
			}
			$('.responsive-tabs').responsiveTabs({
				accordionOn: ['xs', 'sm']
			});
			$('input[name="filtrodia"]').click(function() {
				$( ".filtrodia.active" ).removeClass('active');
				$( $(this).val() ).addClass('active');
			});
		}

		if ($('.mod_red').length) {
			$('.collapse').on('show.bs.collapse', function () {
				$('.collapse.show').collapse('toggle');
			});
		}
	});

	$(function () {
		mobileMenuOutsideClick();
		offcanvasMenu();
		burgerMenu();
		goToTop();
		loaderPage();

	});


});